@import "shared/styles/tools";

.slider {
  position: relative;
}

.container {
  position: relative;
	width: 100%;

  overflow-x: scroll;


  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  --col: 50;
  --position: 0;
  transition-property: left;
  transition-duration: .3s;
  position: relative;
	width: 100%;
  top: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(var(--col), calc(25% - 1.5rem));
  left: calc(var(--position) * 100%);
  
  @include breakpoint(md) {
    grid-template-columns: repeat(var(--col), calc(40% - 1.5rem));
    left: calc(var(--position) * 80%);
  }
  
  @include breakpoint(sm) {
    grid-template-columns: repeat(var(--col), 75%);
    left: 0;
  }
}

.buttonGroup {
  position: absolute;
  top: -3.2rem;
  right: 0;
  display: flex;
  gap: 1rem;
  
  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:disabled {
          opacity: 0.4;
    }
  }
}
