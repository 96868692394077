@import 'shared/styles/tools';
@import 'shared/styles/elements';

.youtubeSection {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: var(--padding-lg);

  @include breakpoint(lg) {
    margin-top: 3rem;
  }
}

.youtubeHeading {
  color: var(--text-primary);
  font-family: var(--sans-serif);
  font-style: normal;
  font-weight: var(--font-semi-bold);
  font-size: var(--heading-barlow-1);
  line-height: 130%;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
}

.playlistHeading {
  color: var(--text-primary);
  font-family: var(--sans-serif);
  position: relative;
  display: flex;
  border-top: 1px solid var(--text-primary);

  h3 {
    margin-top: 0.7rem;
    font-style: normal;
    font-weight: var(--font-semi-bold);
    line-height: 130%;
    text-transform: uppercase;
  }
}

.sliderContainer { 
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
}
