@import "shared/styles/tools";
@import "shared/styles/elements";

.background {
  background-color: #F5F5F5;
}

.streamingHeroContainer {
  background-color: white;
}

.heroPosterContainer {
  position: relative;
  
  @include breakpoint(lg) {
    aspect-ratio: 16 / 9;
  }
}
.streamingVideoPoster {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.watchButton {
  @include btnPill();
  @include btnDark();
  
  cursor: pointer;
  position: absolute;
  left: var(--padding-lg);
  z-index: 2;

  bottom: var(--padding-lg);
  font-size: 1rem;;
  padding: .15rem 1.25rem;

  & svg {
    fill: white;
    height: 21px;
    width: 21px;
  }

  & span {
    margin-left: var(--padding-sm);
  }

  &:hover {
    border-color: var(--brand-primary);
    color: var(--brand-primary);
    
    svg {
      fill: var(--brand-primary);
    }
  }
}


.ytplayerContainer {
  background-color: #4B4B4B;
  position: relative;
  height: 100%;
  min-height: 350px;
}

.iframe {
  height: 100%;
  margin: 0 auto;
  // opacity: 0;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
}


.headerContent {
  display: flex !important;
  padding: var(--padding-md) var(--padding-lg) var(--padding-md) var(--padding-md) !important;

  @include breakpoint(sm) {
    padding: var(--padding-md) !important;
  }
  
  @include breakpoint(md) {
    padding: var(--padding-md) !important;
  }

}

.heading {
  @include heroHeading();
  margin-top: var(--padding-xl);
  
  @include breakpoint(sm) {
    margin-top: var(--padding-md);
  }
  
  @include breakpoint(md) {
    margin-top: 0;
  }
}

.contentMeta {
  color: var(--brand-primary);
  font-size: 0.875rem;
  text-transform: uppercase;
}

.date {
  color: var(--icon-bg);
  margin-left: var(--padding-md);
}

.headerText {
  border-top: 1px solid var(--bg-reverse);
  font-size: var(--body-text);
  margin-top: var(--padding-md);
  padding-top: var(--padding-md);
  @include bodyText();
}


.socialIcons > a {
  margin-left: var(--padding-md);
  margin-top: var(--padding-md);

  svg {
    fill: black;

    &:hover {
      fill: var(--brand-primary);
    }
  }
}


@include breakpoint(md) {
  .date {
    margin-left: var(--padding-lg);
  }
  .socialIcons {
    margin: 1.5rem 0;
  }
}

.headerText {
  margin-top: auto;
}
.socialIcons {
  margin-top: 1.5rem;
  @include breakpoint(md) {
    margin-top: var(--padding-md);
    margin-bottom: 0;
  }
  @include breakpoint(sm) {
    margin-top: var(--padding-md);
  }
}

@media screen and (min-width: 600px) and (max-width: 795px) {
  .contentMeta {
    font-size: 0.675rem;
  }
  .date {
    margin-left: var(--padding-sm);
  }
}


.permaLink {
  display: inline-block;
  margin-left: .25rem;
  color: var(--ui-action);
  
  &:hover {
    text-decoration: underline;
  }
}