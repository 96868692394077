@import "shared/styles/tools";
@import "shared/styles/elements";

.background {
  background-color: #F5F5F5;
}

.streamingHeroContainer {
  background-color: white;
}

.heroPosterContainer {
  position: relative;
  min-width: 320px;
  min-height: 213px;
}


.iframe {
  height: 100%;
  margin: 0 auto;
  // opacity: 0;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
}


.headerContent {
  display: flex !important;
  padding: var(--padding-md) var(--padding-lg) var(--padding-md) var(--padding-md) !important;

  @include breakpoint(sm) {
    padding: var(--padding-md) !important;
  }
  
  @include breakpoint(md) {
    padding: var(--padding-md) !important;
  }

}

.heading {
  @include heroHeading();
  margin-top: var(--padding-xl);
  
  @include breakpoint(sm) {
    margin-top: var(--padding-md);
  }
  
  @include breakpoint(md) {
    margin-top: 0;
  }
}

.contentMeta {
  color: var(--brand-primary);
  font-size: 0.875rem;
  text-transform: uppercase;
}

.date {
  color: var(--icon-bg);
  margin-left: var(--padding-md);
}

.headerText {
  border-top: 1px solid var(--bg-reverse);
  font-size: var(--body-text);
  margin-top: var(--padding-md);
  padding-top: var(--padding-md);
  @include bodyText();
}


.socialIcons > a {
  margin-left: var(--padding-md);
  margin-top: var(--padding-md);

  svg {
    fill: black;

    &:hover {
      fill: var(--brand-primary);
    }
  }
}


@include breakpoint(md) {
  .date {
    margin-left: var(--padding-lg);
  }
  .socialIcons {
    margin: 1.5rem 0;
  }
}

.headerText {
  margin-top: auto;
}
.socialIcons {
  margin-top: 3.5rem;
  @include breakpoint(md) {
    margin-bottom: 0;
  }
  @include breakpoint(sm) {
    margin-top: var(--padding-md);
  }
}

@media screen and (min-width: 600px) and (max-width: 795px) {
  .contentMeta {
    font-size: 0.675rem;
  }
  .date {
    margin-left: var(--padding-sm);
  }
}