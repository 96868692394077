@import "tools";

/*
 * Styled elements per Figma Approved Homepage Design
 *
 * How to use:
 *
 * In your CSS Module stylesheet, import the elements file and add mixin to your class
    
 @import "shared/styles/elements";

    .heroHeading {
      @include heroHeading();
    }

*/


/* Buttons */
@mixin btnPill() {
  background-color: var(--brand-primary);
  border: none;
  border-radius: 5rem;
  color: var(--text-primary);
  height: 50px;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    box-shadow: 0 0 1px 2px var(--brand-secondary);
  }
}

@mixin btnDark {
  background-color: var(--bg-reverse);
  border: 1px solid var(--text-reverse);
  color: var(--text-reverse);
  font-size: 1.125rem;
}

@mixin heroHeading() {
  color: var(--text-primary);
  font-family: var(--source-serif);
  font-style: normal;
  font-weight: var(--font-regular);
  font-size: 2rem;
  @include breakpoint(lg) {
    font-size: 2.375rem;
  }
  line-height: 120%;
  letter-spacing: 0em;
}

@mixin wireHeading() {
  color: var(--text-reverse);
  font-style: normal;
  @include font($weight: semi-bold, $size: 1.5rem);
  line-height: 130%;
  text-transform: uppercase;

  span {
    color: var(--brand-primary);
  }
}

@mixin verticalHeading() {
  color: var(--text-primary);
  font-style: normal;
  @include font($weight: semi-bold, $size: 1.5rem);
  line-height: 130%;
}

@mixin newsletterHeading() {
  color: var(--brand-primary);
  font-style: normal;
  @include font($weight: semi-bold, $size: 1.5rem);
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
}

@mixin newsletterSubheading() {
  color: var(--text-reverse);
  font-family: var(--source-serif);
  font-style: normal;
  font-weight: var(--font-regular);
  font-size: 2rem;
  @include breakpoint(md) {
    font-size: 2.8125rem;
  }
  @include breakpoint(lg) {
    font-size: 2.8125rem;
  }
  line-height: 130%;
  text-align: center;
}

@mixin podcastsHeading() {
  color: var(--text-primary);
  font-family: var(--source-serif);
  font-style: normal;
  font-weight: var(--font-regular);
  font-size: 2.5rem;
  @include breakpoint(md) {
    font-size: 2.8125rem;
  }
  @include breakpoint(lg) {
    font-size: 2.8125rem;
  }
  line-height: 130%;
}

@mixin sloganHeading() {
  font-family: var(--source-serif);
  font-style: normal;
  font-weight: var(--font-semi-bold);
  font-size: 1.5rem;
  @include breakpoint(md) {
    font-size: 2rem;
  }
  @include breakpoint(lg) {
    font-size: 2rem;
  }
  line-height: 120%;
}

@mixin bodyText() {
  font-family: var(--barlow);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  @include breakpoint(md) {
    font-size: 0.75rem;
  }
  @include breakpoint(lg) {
    font-size: 1rem;
  }
  line-height: 120%;
  color: var(--black);
}
