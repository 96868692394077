/* BREAKPOINT MIXIN 
 *
 * @param $point: xl | lg | md | sm
 *
 * How to use:
 * 
 * With selectors inside of the media query:

   @include breakpoint(xl) {
    .heading {
      color: blue;
    }
  }

  * With media query inside of a selector:

  .heading {
    color: black;
    @include breakpoint(xl) {
      color: blue;
    }
  }

*/

@mixin breakpoint($point) {
  @if $point == lg {
    @media screen and (min-width: 901px) { @content; }
  }
  @else if $point == md {
    @media screen and (min-width: 601px) and (max-width: 900px) { @content; }
  }
  @else if $point == sm {
    @media screen and (max-width: 600px)  { @content; }
  }
}

@function spacing($n) {
  @return ($n * 0.5) + rem;
}

@mixin font($family: barlow, $weight: regular, $size: 1rem) {

  $fontype: var(--barlow);
  
  $weights: (
    regular: 300,
    medium: 400,
    semi-bold: 500,
    bold: 600,
    );
    
  @if $family == source {
    $weights: (
      regular: 400,
      medium: 500,
      semi-bold: 600,
      );
    $fontype: var(--source-serif);
    }

  $fontweight: $weight;
  @if map-has-key($weights, $weight) {
    $fontweight: map-get($weights, $weight);
  }

  @if $family {
    font-family: $fontype;
  }

  @if $weight {
    font-size: $size;
  }

  @if $size {
    font-weight: $fontweight;
  }
}
