@import "shared/styles/tools";
@import "shared/styles/elements";

.container {
  background-color: var(--bg-secondary);
  padding: 2.5rem 0;
}

.sectionTitle {
  @include breakpoint(sm) {
    text-align: center;
  }
}

@include breakpoint(lg) {
  .buttonContainer {
    display: flex !important;
  }
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  svg {
    fill: white;
  }

  &:hover {
    svg {
      fill: var(--brand-primary);
    }
  }
}
.button:disabled,
.button[disabled] {
  opacity: .4;

  &:hover {
    svg {
      fill: white;
    }
  }
}

.carouselContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 var(--padding-16-24);
  position: relative;
  margin-top: var(--padding-lg);
  width: 100%;

  button {
    svg {
      fill: white;
    }
    &:hover {
      svg {
        fill: var(--brand-primary);
      }
    }

    &:disabled,
    &[disabled] {
      opacity: .4;
    }
  }

}

.tileContainer {
  position: relative;

  &::before {
    background-color: var(--text-rule);
    content: '';
    display: block;
    height: 1px;
    opacity: .3;
    position: absolute;
    top: 2px;
    width: 110%;
  }
}

.tile {
  p {
    overflow: hidden;
  }
}

.spacer {
  opacity: 0;
}

.meta {
  font-family: var(--barlow);
  font-size: 0.75rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-transform: uppercase;
  gap: .5rem;

  &::before {
    background-color: var(--ui-alert-reverse);
    border-radius: 18px;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: -2px;
    width: 9px;
  }


}

.timeAgo {
  color: var(--ui-alert-reverse);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  time {
    white-space: nowrap;
  }

  svg {
    margin-right: var(--padding-md);

    @include breakpoint(md) {
      margin-right: var(--padding-sm);
    }
    @include breakpoint(sm) {
      margin-right: var(--padding-sm);
    }
  }
}

.location {
  color: var(--icon-bg-hover);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;

  span {
    margin-left: var(--padding-sm);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 2 2 60%;

  }
}
