@import 'shared/styles/tools';
@import 'shared/styles/elements';

.beehiivSection {
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: var(--padding-lg);

  @include breakpoint(lg) {
    margin: 3rem 0;
  }
}

.beehiivHeading {
  color: var(--text-primary);
  font-family: var(--sans-serif);
  font-style: normal;
  font-weight: var(--font-semi-bold);
  font-size: var(--heading-barlow-1);
  line-height: 130%;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
}

.sliderContainer { 
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 var(--padding-16-24);
  position: relative;
  width: 100%;
}
