@import 'shared/styles/tools';

.sectionHeader {
  position: relative;
  display: inline-block;
  span {
    background-image: linear-gradient(0deg, var(--brand-primary) 50%, transparent 50%);
    background-size: 100% 100%;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 0 2px;
  }
}
